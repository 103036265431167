import '../scss/tabbed-content.scss'

window.addEventListener('DOMContentLoaded', () => {
    const tabButtons = document.querySelectorAll('.custom-tab');

    tabButtons.forEach(button => {
        button.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();

            tabButtons.forEach(btn => btn.classList.remove('active'));
            button.classList.add('active');
        });
    });
});
